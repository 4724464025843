import * as React from 'react'
import { PageProps, Link } from 'gatsby'
import { Layout } from '../components/default-layout'
import { ActionButtons } from '../components/action-buttons'
import { DefaultLogo } from '../components/logo'
import { Slider } from '../components/slider'

const Home: React.FC<PageProps> = () => {
  return (
    <Layout theme={'blue'} visible={{}} logo={<DefaultLogo />}>
      <Slider
        id={'inicio'}
        wallpaper={'homeSlider'}
        overlay={0.5}
        business={'Domicilios 24/7'}
        pageTitle={'Página no encontrada'}
        pageDescription={
          <>Perdón 😔 - No pudimos encontrar lo que estás buscando.</>
        }
        action={
          <>
            <Link to={'/'} className={'btn btn-green mb-3 w-full md:w-auto'}>
              Ir al inicio
            </Link>
            <ActionButtons />
          </>
        }
      />
    </Layout>
  )
}

export default Home
