import * as React from 'react'
import { Link } from 'gatsby'
import { FaCommentDots } from 'react-icons/fa'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { useUserAction } from './client-identity'
import { LeftIcon } from './button-icon'
import { useToast } from './toast'

export const ActionButtons: React.FC<{ addMargin?: boolean }> = ({
  addMargin = true,
}) => {
  return (
    <div
      className={
        'flex flex-col md:flex-row md:space-x-3.5 space-y-3.5 md:space-y-0'
      }
    >
      <Link className={'btn btn-purple'} to={'/cerrajeria'}>
        Cerrajería
      </Link>
      <Link className={'btn btn-blue'} to={'/electricistas'}>
        Electricista
      </Link>
      <Link className={'btn btn-green'} to={'/plomeria'}>
        Plomería
      </Link>
    </div>
  )
}

export interface ShopButtonProps extends React.HTMLProps<HTMLAnchorElement> {
  href?: string
}

export const ShopButton = React.forwardRef<HTMLAnchorElement, ShopButtonProps>(
  ({ type = 'button', href, ...props }, ref) => {
    const [send] = useUserAction()

    return (
      <a
        ref={ref}
        href={href}
        className={'btn btn-purple'}
        onClick={ev => {
          props.onClick?.(ev)

          trackCustomEvent({
            category: 'button',
            action: 'click',
            label: `want-chat`,
          })

          send('Catálogo')
        }}
        {...props}
      >
        <LeftIcon>
          <FaCommentDots className={'text-xl'} />
        </LeftIcon>
        Catálogo
      </a>
    )
  },
)
